import { Button, Link, Typography } from '@mui/material';
import { useState } from 'react';
import Impressum from './Impressum';
import React from 'react';

/**
 * Footer
 */
export function Footer(): JSX.Element {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	return (
		<React.Fragment>
			<Impressum open={open} setOpen={setOpen} />
			<Typography
				variant='body2'
				color='textSecondary'
				style={{ position: 'fixed', bottom: '5px', marginLeft: '10px' }}>
				{`Version ${
					process.env.NODE_ENV === 'development'
						? process.env.REACT_APP_TCC_VERSION
						: window.env.TCC_VERSION
				} | Copyright © `}
				{new Date().getFullYear()}
				{' | '}
				<Button
					variant='text'
					onClick={handleClickOpen}
					style={{
						textTransform: 'none',
						textDecoration: 'underline',
						color: 'grey',
					}}>
					Impressum
				</Button>
				{' | '}
				<Link color='inherit' href='https://institute-for-compassion.de/'>
					Institute for Compassion
				</Link>
			</Typography>
		</React.Fragment>
	);
}
