import React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { Face, LocalActivity, Groups } from '@mui/icons-material';
import ResidentsOverviewPanel from '../components/ResidentsOverviewPanel';
import ResidenceEventPanel from '../components/ResidenceEventPanel';
import ResidenceGroupPanel from '../components/ResidenceGroupPanel';

function OverviewPage(props) {
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const [value, setValue] = React.useState(props.location.state?.tabValue ?? 0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const getTabPanels = (state) => {
		let tabs = (
			<Tabs
				value={value}
				onChange={handleChange}
				variant='scrollable'
				scrollButtons='auto'
				aria-label='basic tabs example'>
				<Tab label='Bewohnerübersicht' icon={<Face />} {...a11yProps(0)} />
				<Tab
					label='Heimleben-Events'
					icon={<LocalActivity />}
					{...a11yProps(1)}
				/>
				<Tab label='Heimleben-Gruppen' icon={<Groups />} {...a11yProps(2)} />
			</Tabs>
		);
		let panels = (
			<>
				<ResidentsOverviewPanel value={value} index={0} />
				<ResidenceEventPanel value={value} index={1} />
				<ResidenceGroupPanel value={value} index={2} />
			</>
		);

		return (
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>{tabs}</Box>
				{panels}
			</Box>
		);
	};

	return (
		<React.Fragment>
			<Grid container direction='column' spacing={2} sx={{ padding: 5 }}>
				<Grid item xs={12}>
					{getTabPanels(props.location.state)}
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default OverviewPage;
