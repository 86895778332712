import React from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	ThemeProvider,
	MenuItem,
	FormControl,
	FormLabel,
	Select,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Save, Close } from '@mui/icons-material';
import useForm from './useForm';
import SelectRadioButton from './SelectRadioButton';
import SelectDate from './SelectDate';
import { getNetworkApi } from './keycloak';
import {
	isEmptyField,
	isValidBirthDate,
	isValidDate,
	setEmptyStringToNull,
} from './format';

export default function ResidentForm({
	open,
	setOpen,
	units,
	unitIndex,
	residentFormType,
	currentResident,
	setResidentData,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}) {
	const handleClose = () => {
		setOpen(false);
	};

	const { Values, setValues, UnitIndex, handleInputChange, handleUnitChange } =
		useForm(currentResident, units, unitIndex);

	const saveNewResident = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postResident(
				Values.tenantIdentifier,
				Values.residenceIdentifier,
				Values.unitIdentifier,
				{
					residenceIdentifier: Values.residenceIdentifier,
					unitIdentifier: Values.unitIdentifier,
					firstName: setEmptyStringToNull(Values.firstName),
					surname: setEmptyStringToNull(Values.surname),
					gender: Values.gender,
					room: setEmptyStringToNull(Values.room),
					associatedCaregiver: setEmptyStringToNull(Values.associatedCaregiver),
					birthday: Values.birthday != null ? Values.birthday : undefined,

					birthplace: Values.birthplace != null ? Values.birthplace : undefined,
					birthname: Values.birthname != null ? Values.birthname : undefined,
					nickname: Values.nickname != null ? Values.nickname : undefined,
					residentSince:
						Values.residentSince != null ? Values.residentSince : undefined,
				}
			);
			setAlertText('Bewohner:in wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Bewohner:in wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const UpdateResident = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putResident(
				Values.tenantIdentifier,
				Values.residenceIdentifier,
				Values.unitIdentifier,
				Values.residentId,
				{
					residenceIdentifier: Values.residenceIdentifier,
					unitIdentifier: Values.unitIdentifier,
					firstName: Values.firstName,
					surname: Values.surname,
					gender: Values.gender,
					room: Values.room,
					associatedCaregiver: Values.associatedCaregiver,
					birthday: Values.birthday != null ? Values.birthday : undefined,
					birthplace: Values.birthplace != null ? Values.birthplace : undefined,
					birthname: Values.birthname != null ? Values.birthname : undefined,
					nickname: Values.nickname != null ? Values.nickname : undefined,
					residentSince:
						Values.residentSince != null ? Values.residentSince : undefined,
				}
			);
			setAlertText('Eintrag für Bewohner:in wurde erfolgreich geändert');
			setShowSuccessAlert(true);
			setResidentData && setResidentData(Values);
		} catch (error) {
			setAlertText('Eintrag für Bewohner:in wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const genderItems = [
		{ id: 'male', title: 'männlich' },
		{ id: 'female', title: 'weiblich' },
		{ id: 'divers', title: 'divers' },
	];

	function isSaveButtonEnabled() {
		let isEnabled: boolean;
		if (
			Values.firstName === '' ||
			Values.surname === '' ||
			Values.room === '' ||
			Values.associatedCaregiver === '' ||
			(!isEmptyField(Values.birthday) && !isValidBirthDate(Values.birthday)) ||
			(!isEmptyField(Values.residentSince) &&
				!isValidDate(Values.residentSince))
		) {
			isEnabled = false;
		} else {
			isEnabled = true;
		}
		return isEnabled;
	}

	const isDisplay: boolean = residentFormType === 'display';

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>
						{residentFormType === 'new'
							? 'Bewohner:in anlegen'
							: residentFormType === 'edit'
							? 'Bewohner:in bearbeiten'
							: 'Bewohner:in anzeigen'}
					</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={12} sx={{ marginTop: 1 }}>
									{residentFormType === 'new' ? (
										<FormControl variant='outlined' sx={{ width: '100%' }}>
											<FormLabel>Wohnbereich</FormLabel>
											<Select
												variant='outlined'
												label='Wohnbereich'
												name='Unitindex'
												value={UnitIndex}
												onChange={handleUnitChange}>
												{units.map((el, index) => (
													<MenuItem key={index} value={index}>
														{' '}
														{el.residenceIdentifier} - {el.unitIdentifier}{' '}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										<TextField
											fullWidth
											label='Wohnbereich'
											name='unitIdentifier'
											id='outlined-size-normal'
											value={Values.unitIdentifier}
											disabled={true}
											onChange={handleInputChange}
											required
											style={{
												marginTop: 2,
											}}
										/>
									)}
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label='Vorname'
										name='firstName'
										id='outlined-size-normal'
										value={Values.firstName}
										onChange={handleInputChange}
										disabled={isDisplay}
										inputProps={{
											maxLength: 50,
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label='Nachname'
										name='surname'
										id='outlined-size-normal'
										value={Values.surname}
										onChange={handleInputChange}
										disabled={isDisplay}
										inputProps={{
											maxLength: 50,
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label='Rufname'
										name='nickname'
										id='outlined-size-normal'
										value={Values.nickname}
										disabled={isDisplay}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 50,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label='Geburtsname'
										name='birthname'
										id='outlined-size-normal'
										value={Values.birthname}
										disabled={isDisplay}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 50,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<SelectDate
										label='Geburtsdatum'
										name='birthday'
										value={
											Values.birthday === undefined ? null : Values.birthday
										}
										Values={Values}
										disabled={isDisplay}
										setValues={setValues}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label='Geburtsort'
										name='birthplace'
										id='outlined-size-normal'
										value={Values.birthplace}
										disabled={isDisplay}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 50,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<SelectRadioButton
										name='gender'
										label='Geschlecht'
										value={Values.gender}
										isDisplay={isDisplay}
										onChange={handleInputChange}
										items={genderItems}
										inputProps={{
											maxLength: 50,
										}}
									/>
								</Grid>

								<Grid item xs={4}>
									<TextField
										fullWidth
										label='Zimmernummer'
										name='room'
										id='outlined-size-normal'
										value={Values.room}
										disabled={isDisplay}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 50,
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label='Bezugspflegekraft'
										name='associatedCaregiver'
										id='outlined-size-normal'
										value={Values.associatedCaregiver}
										onChange={handleInputChange}
										disabled={isDisplay}
										inputProps={{
											maxLength: 50,
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<SelectDate
										label='Bewohner:in seit'
										name='residentSince'
										value={
											Values.residentSince === undefined
												? null
												: Values.residentSince
										}
										Values={Values}
										disabled={isDisplay}
										setValues={setValues}
									/>
								</Grid>

								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />}{' '}
												{residentFormType !== 'display'
													? 'Abbrechen'
													: 'Schließen'}
											</Button>
										</Grid>
										<Grid item>
											{residentFormType !== 'display' && (
												<Button
													variant='contained'
													onClick={
														residentFormType === 'new'
															? saveNewResident
															: UpdateResident
													}
													disabled={!isSaveButtonEnabled()}>
													{<Save />} Speichern
												</Button>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
