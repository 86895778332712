import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import TextField from '@mui/material/TextField';
import { addTime } from './format';

export default function SelectDate(props) {
	const { name, label, value, Values, setValues, disabled, enableFuture } =
		props;

	const onDateChange = (newValue) => {
		if (newValue === null) {
			setValues({
				...Values,
				[name]: null,
			});
		} else {
			setValues({
				...Values,
				[name]: addTime(new Date(newValue)),
			});
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
			<DatePicker
				disableFuture={!enableFuture}
				label={label}
				value={value}
				onChange={onDateChange}
				disabled={disabled}
				renderInput={(params) => (
					<TextField
						{...params}
						inputProps={{
							...params.inputProps,
							placeholder: 'tt.mm.jjjj',
						}}
						fullWidth
					/>
				)}
			/>
		</LocalizationProvider>
	);
}
