import { Close } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	Grid,
	Link,
	ThemeProvider,
	Typography,
} from '@mui/material';
import React from 'react';
import theme from '../theme';

export default function Confirm({ open, setOpen }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog open={open} onClose={handleClose}>
					<DialogContent>
						<Typography variant='h4'>Impressum</Typography>
						<br />
						<Typography variant='h6'>ANGABEN GEMÄß § 5 TMG </Typography>

						<Typography variant='body1'>
							Institute for Compassion gGmbH
							<br />
							Blumenstraße 39
							<br />
							69115 Heidelberg
							<br />
							<br />
							Handelsregister: 738071
							<br />
							Registergericht: Mannheim
							<br />
							<br />
							Vertreten durch: <br />
							Petra Johns <br />
							Prof. Dr. Jan Mayer
						</Typography>
						<br />
						<Typography variant='h6'>KONTAKT</Typography>
						<Typography variant='body1'>
							Telefon: +49 (0)6221 725 7516
							<br />
							E-Mail: info@institute-for-compassion.de
						</Typography>
						<br />
						<Typography variant='h6'>REDAKTIONELL VERANTWORTLICH</Typography>
						<Typography variant='body1'>
							Petra Johns <br />
							Blumenstraße 39 <br />
							69115 Heidelberg
						</Typography>
						<br />
						<Typography variant='h6'>TECHNISCHE UMSETZUNG</Typography>
						<Typography variant='body1'>
							d-fine GmbH <br />
							<Link target="_blank" href='https://www.d-fine.com'>www.d-fine.com</Link>
						</Typography>
						<br />
						<Typography variant='h6'>EU-STREITSCHLICHTUNG</Typography>
						<Typography variant='body1'>
							Die Europäische Kommission stellt eine Plattform zur
							Online-Streitbeilegung (OS) bereit:&nbsp;
							<Link target="_blank" href='https://ec.europa.eu/consumers/odr'>
								https://ec.europa.eu/consumers/odr
							</Link>
							. <br />
							Unsere E-Mail-Adresse finden Sie oben im Impressum.
						</Typography>
						<br />
						<Typography variant='h6'>
							VERBRAUCHERSTREIT-BEILEGUNG/UNIVERSAL-SCHLICHTUNGSSTELLE
						</Typography>
						<Typography variant='body1'>
							Wir sind nicht bereit oder verpflichtet, an
							Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
							teilzunehmen.
						</Typography>
						<br />
						<Typography variant='h6'>HAFTUNG FÜR INHALTE</Typography>
						<Typography variant='body1'>
							Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
							Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
							verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
							jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
							Informationen zu überwachen oder nach Umständen zu forschen, die
							auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
							Entfernung oder Sperrung der Nutzung von Informationen nach den
							allgemeinen Gesetzen bleiben hiervon unberührt. Eine
							diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
							Kenntnis einer konkreten Rechtsverletzung möglich. Bei
							Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
							diese Inhalte umgehend entfernen.
						</Typography>

						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={2}>
							<Grid item mr={1}>
								<Button variant='contained' onClick={handleClose}>
									{<Close />} Schließen
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
