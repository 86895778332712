import React, { useEffect, useState } from 'react';

import {
	Badge,
	Box,
	Chip,
	FormControl,
	IconButton,
	LinearProgress,
	linearProgressClasses,
	MenuItem,
	Select,
	styled,
	TextField,
	Tooltip,
} from '@mui/material';
import { Icon } from '@iconify/react';
import {
	Female,
	Male,
	Transgender,
	AutoAwesome,
	Celebration,
	PersonAddAlt1,
	SwitchRight,
	TravelExplore,
	Hail,
	Cake,
	LocalBar,
	LunchDining,
	Dining,
	TheaterComedy,
	CardGiftcard,
	History,
	Attractions,
	VolunteerActivismOutlined,
	OutdoorGrill,
	AutoStories,
	Sports,
	MusicNote,
	Extension,
	Forest,
	ChatBubbleOutline,
	Handyman,
	WbSunny,
} from '@mui/icons-material';
import TapasIcon from '@mui/icons-material/Tapas';
import ImageIcon from '@mui/icons-material/Image';
import IcecreamIcon from '@mui/icons-material/Icecream';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import HeartBrokenRoundedIcon from '@mui/icons-material/HeartBrokenRounded';
import isDate from 'validator/lib/isDate';

import { Tag } from '../generated/models/Tag';

import { Note } from '../generated/models/Note';
import { NoteStatus } from '../generated/models/NoteStatus';
import {
	AssessedProfile,
	AssessmentResult,
	FavoriteFoodType,
	MediaCategory,
	NoteCategory,
	OutsideInput,
	Residence,
	ResidenceEvent,
	ResidenceEventCategory,
	ResidenceEventCategoryMapping,
	ResidenceGroupCategory,
	ResidenceGroupCategoryMapping,
	Resident,
	ResidentMediaData,
	StageOfLife,
} from '../generated';
import theme from '../theme';
import { VisitFrequency } from '../generated/models/VisitFrequency';
import { ReactComponent as TCCLogo } from '../data/icon_tcc_abgerundet.svg';
import { trackPromise } from 'react-promise-tracker';
import { getNetworkApi } from './keycloak';
import { StyledIcon } from '../Styles';

/**
 * Function converts into date with format DD.MM.YYYY
 * @function
 * @param date
 */
export const formatDate = function (date?: Date): string {
	if (!date) return '';

	const options: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	};
	return new Intl.DateTimeFormat('de-DE', options).format(date);
};

export function addTime(inputDate: Date): Date {
	const d = new Date(inputDate.setMinutes(-1 * inputDate.getTimezoneOffset()));
	return d;
}

export function setEmptyStringToNull(value: string) {
	const returnvalue = value === '' ? null : value;
	return returnvalue;
}

export function isAnniversary(anniversaryDate: Date) {
	if (!anniversaryDate) return false;
	let date: Date = new Date();
	if (
		anniversaryDate?.getDate() === date.getDate() &&
		anniversaryDate?.getMonth() === date.getMonth()
	)
		return true;
	else return false;
}

export function isTagTicked(TagValues: Tag[], tagId: number) {
	if (
		Object.entries(TagValues).length === 0 ||
		TagValues.find((tag) => tag.tagId === tagId) === undefined
	) {
		return false;
	} else return true;
}

export function isAuthorizedRelative(role: String) {
	if (role !== undefined && role.match('Bevollmächtigte')) {
		return true;
	} else {
		return false;
	}
}

export function isCaringRelative(role: String) {
	if (role !== undefined && role.match('Betreuer')) {
		return true;
	} else {
		return false;
	}
}

export function isMediaCategoryTicked(
	mediaCategories: MediaCategory[],
	id: number
) {
	if (
		mediaCategories === undefined ||
		Object.entries(mediaCategories).length === 0 ||
		mediaCategories.find((item) => item.mediaCategoryId === id) === undefined
	) {
		return false;
	} else return true;
}

export function isIdTicked(values: number[], id: number) {
	if (
		Object.entries(values).length === 0 ||
		values.find((item) => item === id) === undefined
	) {
		return false;
	} else return true;
}

export const handleKeyPress = (e) => {
	//if triggered by pressing the enter key
	if (e.key === 'Enter') {
		e.preventDefault();
	}
};

export function returnTagChips(
	tags: Tag[],
	chosenTags: string,
	height: number
) {
	let Height = '50%';
	if (height) {
		Height = 'fit-content(' + height + '%)';
	}
	return (
		<React.Fragment>
			{tags
				.filter((el) => el.tagGroupIdentifier === chosenTags)
				.map((el, index) =>
					el.tagGroupIdentifier === 'SIS' ? (
						<Chip
							key={index}
							title={el.tagName}
							sx={{
								borderColor: determineChipColor(el.tagAbbreviation),
								color: determineChipColor(el.tagAbbreviation),
								mb: 1,
							}}
							size='medium'
							style={{
								height: Height,
							}}
							label={el.tagAbbreviation}
							variant='outlined'
						/>
					) : (
						<Box> {determineTagIcon(el.tagAbbreviation)} </Box>
					)
				)}
		</React.Fragment>
	);
}

export function filterForSISTags(filter: object, rowData: Note): boolean {
	let { ...singlefilter } = filter;
	let answer: boolean = true;

	for (let filterobj in singlefilter) {
		if (
			!rowData.tags
				.filter((x) => x.tagGroupIdentifier === 'SIS')
				.map((el) => el.tagAbbreviation)
				.toString()
				.toUpperCase()
				.includes(singlefilter[filterobj].toString().toUpperCase())
		) {
			answer = false;
		}
	}
	return answer;
}

export function filterForEmotion(filter: object, rowData: Note): boolean {
	let { ...singlefilter } = filter;
	let answer: boolean = true;

	for (let filterobj in singlefilter) {
		if (
			!rowData.tags
				.filter((x) => x.tagGroupIdentifier === 'EMOTION')
				.map((el) => el.tagAbbreviation)
				.toString()
				.toUpperCase()
				.includes(singlefilter[filterobj].toString().toUpperCase())
		) {
			answer = false;
		}
	}
	return answer;
}

export function filterForMediaCategory(
	filter: object,
	rowData: ResidentMediaData
): boolean {
	let { ...singlefilter } = filter;
	let answer: boolean = true;

	for (let filterobj in singlefilter) {
		if (
			!rowData.mediaCategories
				.map((el) => el.name)
				.toString()
				.toUpperCase()
				.includes(singlefilter[filterobj].toString().toUpperCase())
		) {
			answer = false;
		}
	}
	return answer;
}

export function isNew(residentSince: Date) {
	const fourWeeksAgo = new Date();
	fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 28);
	return +fourWeeksAgo > +residentSince;
}

export function GetNewIcon(residentSince: Date, gridcenter: Boolean) {
	if (!isNew(residentSince) && gridcenter)
		return (
			<Tooltip title='Bewohner:in ist neu'>
				<WbSunny
					fontSize='medium'
					color='primary'
					sx={{ position: 'relative', top: '5px', color: 'orange' }}
				/>
			</Tooltip>
		);
	else if (!isNew(residentSince) && !gridcenter)
		return (
			<Tooltip title='Bewohner:in ist neu'>
				<WbSunny
					fontSize='medium'
					color='primary'
					sx={{ position: 'relative', top: '0px', color: 'orange' }}
				/>
			</Tooltip>
		);
	else
		return (
			<Tooltip title='Bewohner:in ist neu'>
				<WbSunny
					fontSize='medium'
					color='primary'
					visibility='hidden'
					sx={{ position: 'relative', top: '5px', color: 'orange' }}
				/>
			</Tooltip>
		);
}

export function getGenderIcon(gender: string) {
	if (gender === 'female')
		return (
			<Female
				fontSize='medium'
				color='primary'
				sx={{ position: 'relative', top: '5px' }}
			/>
		);
	else if (gender === 'male')
		return (
			<Male
				fontSize='medium'
				color='primary'
				sx={{ position: 'relative', top: '5px' }}
			/>
		);
	else
		return (
			<Transgender
				fontSize='medium'
				color='primary'
				sx={{ position: 'relative', top: '5px' }}
			/>
		);
}

function getSmileyEmotion(tagname: string): string {
	let smileySpec: string;

	if (tagname === 'Trauer') {
		smileySpec = 'fa-regular:sad-tear';
	} else if (tagname === 'Wut') smileySpec = 'fe:rage';
	else if (tagname === 'Glück') smileySpec = 'mdi:emoticon-happy-outline';
	else if (tagname === 'Reue') {
		smileySpec = 'fe:disappointed';
	} else if (tagname === 'Angst') {
		smileySpec = 'mdi:emoticon-frown-outline';
	} else if (tagname === 'Hoffnung') smileySpec = 'mdi:hands-pray';
	else if (tagname === 'Trost') smileySpec = 'mdi:hand-heart-outline';
	else if (tagname === 'Zufriedenheit')
		smileySpec = 'heroicons-outline:thumb-up';
	else if (tagname === 'Eskalation') smileySpec = 'carbon:cloud-lightning';
	else if (tagname === 'Stolz') smileySpec = 'mdi:arm-flex-outline';
	else smileySpec = '';

	return smileySpec;
}

export function determineTagIcon(tagname: string) {
	let smileysize = '24px';
	let smiley = getSmileyEmotion(tagname);
	return (
		<Tooltip title={tagname} placement='right'>
			<Box>
				<Icon
					icon={smiley}
					style={{
						fontSize: smileysize,
						fontWeight: 700,
						color: theme.palette.primary.main,
					}}
				/>
			</Box>
		</Tooltip>
	);
}

export function determineTagIconInLineSize(tagname: string, size: string) {
	let smileysize = '26px';
	let smiley = getSmileyEmotion(tagname);
	return (
		<Tooltip title={tagname} placement='right'>
			<Icon
				key={tagname}
				icon={smiley}
				style={{ fontSize: smileysize, color: theme.palette.primary.main }}
			/>
		</Tooltip>
	);
}

export function determineTagIconInLine(tagname: string) {
	let smileysize = '24px';
	let smiley = getSmileyEmotion(tagname);
	return (
		<Tooltip title={tagname} placement='right'>
			<Icon key={tagname} icon={smiley} style={{ fontSize: smileysize }} />
		</Tooltip>
	);
}

export function determineChipColor(tagname: string) {
	let chipColor;
	if (tagname === 'Kog. & Kom.') chipColor = '#ff8c00';
	//orange
	else if (tagname === 'Mob. & Bew.') chipColor = '#ffd800';
	//yellow
	else if (tagname === 'Krankh. Anf. & Bel.') chipColor = '#2e8b57';
	//green
	else if (tagname === 'Selbstvers.') chipColor = '#6f2da8';
	//purple
	else if (tagname === 'Leben in soz. Bez.') chipColor = '#7cb9e8';
	//blue
	else if (tagname === 'Wohnen / Häusl.') chipColor = '#d9004c';
	//light grey
	else chipColor = 'grey';
	return chipColor;
}

export function determineStatusChipColor(notestatus: string) {
	let chipColor;
	if (notestatus === 'created') chipColor = '#74c365';
	//green
	else if (notestatus === 'updated') chipColor = '#f4c430';
	//yellow
	else if (notestatus === 'transferred') chipColor = '#a9a9a9';
	//grey
	else chipColor = '#696969'; //darkgrey
	return chipColor;
}

export function getMediaFileType(selectedFileName: string): string {
	if (selectedFileName === undefined) return null;
	const fileExtension = selectedFileName.split('.').pop().toLowerCase();
	switch (fileExtension) {
		case 'jpg':
		case 'jpeg':
		case 'svg':
		case 'webp':
		case 'png':
		case 'gif':
		case 'avif':
		case 'apng':
			return 'img';
		case 'pdf':
			return 'pdf';
		case 'mp4':
		case 'mov':
		case 'mwv':
			return 'video';
		case 'mp3':
		case 'wav':
		case 'm4a':
		case 'flac':
		case 'wma':
			return 'audio';
		default:
			return null;
	}
}

export function addHttp(linkstring: string) {
	if (linkstring.startsWith('www.')) return 'https://' + linkstring;
	else return linkstring;
}

export function roundPercentage(perc: number) {
	let x = Math.round(perc);
	return x.toString() + '%';
}

export function getMarksForSlider(
	scaleMin: number,
	scaleMax: number,
	guidanceMin: string,
	guidanceMax: string
) {
	let marks = [];
	for (let i = scaleMin; i <= scaleMax; ++i) {
		let newMark = {
			value: i,
		};
		if (i === scaleMin) {
			let newMark = {
				value: i,
				label: guidanceMin,
			};
			marks.push(newMark);
		} else if (i === scaleMax) {
			let newMark = {
				value: i,
				label: guidanceMax,
			};
			marks.push(newMark);
		} else {
			marks.push(newMark);
		}
	}
	return marks;
}

export function isAuthorizedForRoleTenant(
	userRoleList: string[],
	baseRole: string,
	tenantIdentifier: string,
	residenceIdentifier: string,
	unitIdentifier: string
): boolean {
	if (residenceIdentifier !== undefined) {
		if (unitIdentifier !== undefined) {
			return userRoleList.includes(
				'R-' +
					baseRole +
					'-' +
					tenantIdentifier +
					'-' +
					residenceIdentifier +
					'-' +
					unitIdentifier
			);
		} else {
			return userRoleList.includes(
				'R-' + baseRole + '-' + tenantIdentifier + '-' + residenceIdentifier
			);
		}
	} else {
		return userRoleList.includes('R-' + baseRole + '-' + tenantIdentifier);
	}
}

export function isValidDate(date: any): boolean {
	const upperLimit = 3000;
	const lowerLimit = 1800;
	return (
		isDate(date) &&
		date.getFullYear() > lowerLimit &&
		date.getFullYear() < upperLimit
	);
}

export function isValidBirthDate(date: any): boolean {
	const lowerLimit = 1800;
	const currentTime = new Date();

	return isDate(date) && date.getFullYear() > lowerLimit && date <= currentTime;
}

export function isEmptyField(field: any): boolean {
	return (
		field === '' || field === null || field === undefined || field === void 0
	);
}

export function makeMenuItemForName(currentValue, index) {
	return (
		<MenuItem key={index} value={currentValue.name}>
			{' '}
			{currentValue.name}{' '}
		</MenuItem>
	);
}

export function makeMenuItemForResident(elem: Resident, index) {
	return (
		<MenuItem key={index} value={elem.residentId}>
			{' '}
			{elem.surname}{' '}
		</MenuItem>
	);
}

export function makeMenuItemForValue(elem, index) {
	return (
		<MenuItem key={index} value={elem.value}>
			{' '}
			{elem.name}{' '}
		</MenuItem>
	);
}

export function makeMenuItemForSubCategories(
	elem: ResidenceEventCategoryMapping,
	index
) {
	return (
		<MenuItem key={index} value={elem.residenceEventSubCategory}>
			{' '}
			{elem.residenceEventSubCategory}{' '}
		</MenuItem>
	);
}

export function makeMenuItemForGroupSubCategories(
	elem: ResidenceGroupCategoryMapping,
	index
) {
	return (
		<MenuItem key={index} value={elem.residenceGroupSubCategory}>
			{' '}
			{elem.residenceGroupSubCategory}{' '}
		</MenuItem>
	);
}

export function makeMenuItemForNoteStatusName(currentValue: NoteStatus, index) {
	return (
		<MenuItem key={index} value={currentValue.noteStatusName}>
			{' '}
			{currentValue.noteStatusName}{' '}
		</MenuItem>
	);
}

export function nameWithNickname(residentData: Resident) {
	if (
		residentData.firstName !== undefined &&
		residentData.surname !== undefined
	) {
		let nickname = residentData.nickname
			? ' (' + residentData.nickname + ')'
			: '';
		return residentData.firstName + ' ' + residentData.surname + nickname;
	} else {
		return '';
	}
}

export function nameFieldWithGenderIcon(residentData: Resident) {
	if (
		residentData.firstName !== undefined &&
		residentData.surname !== undefined
	) {
		return (
			<span>
				{' '}
				{residentData.firstName + ' ' + residentData.surname + ' '}
				{residentData.nickname ? '(' + residentData.nickname + ')' : ''}{' '}
				{getGenderIcon(residentData.gender)}
			</span>
		);
	} else {
		return null;
	}
}

export function nameFieldForResidentCard(residentData: Resident) {
	if (
		residentData.firstName !== undefined &&
		residentData.surname !== undefined
	) {
		return (
			<span>
				{' '}
				{residentData.firstName + ' ' + residentData.surname + ' '}
				<br />
				{residentData.nickname ? '(' + residentData.nickname + ')' : ''}{' '}
				{getGenderIcon(residentData.gender)}
			</span>
		);
	} else {
		return null;
	}
}

export function nameFieldForNoteCard(noteData: Note) {
	if (noteData.title !== undefined) {
		return <span> {noteData.title}</span>;
	} else {
		return null;
	}
}

export function dateField(date?: Date) {
	return date === undefined || date === null ? (
		<em>unbekannt</em>
	) : (
		formatDate(date)
	);
}

export function dateField2(date?: Date) {
	return date === undefined || date === null ? 'unbekannt' : formatDate(date);
}

interface DropDownFilterProps {
	width: string;
	valueList:
		| StageOfLife[]
		| { value: NoteCategory }[]
		| { value: ResidenceEventCategory }[];
	value: string | NoteCategory | ResidenceEventCategory;
	onChange: any;
	name: string;
}

export function dropDownFilter({
	value,
	valueList,
	onChange,
	width,
	name,
}: DropDownFilterProps) {
	return (
		<FormControl variant='outlined' sx={{ width: { width } }}>
			{Object.entries(valueList).length !== 0 && (
				<Select
					variant='outlined'
					name={name}
					value={value}
					onChange={onChange}
					defaultValue={value}>
					<MenuItem value={''}>
						<em>
							<b>Alle</b>
						</em>
					</MenuItem>
					{name === 'stageOfLife'
						? valueList.map(makeMenuItemForName)
						: valueList.map(makeMenuItemForValue)}
				</Select>
			)}
		</FormControl>
	);
}

export interface EventIdData {
	tenantIdentifier: string;
	residenceIdentifier: string;
}
export function getEventIdData(residence: Residence): EventIdData {
	return {
		tenantIdentifier: residence.tenantIdentifier,
		residenceIdentifier: residence.residenceIdentifier,
	};
}
export function getEventIdDataFromPath(props): EventIdData {
	const tenantId: string = props.match.params.tenantId;
	const residenceId: string = props.match.params.residenceId;

	const eventIdData: EventIdData = {
		tenantIdentifier: tenantId,
		residenceIdentifier: residenceId,
	};

	return eventIdData;
}
export interface ResidenceEventIdData {
	residenceEventId: number;
}
export function getResidenceEventIdData(
	residenceEvent: ResidenceEvent
): ResidenceEventIdData {
	return {
		residenceEventId: residenceEvent.residenceEventId,
	};
}

export interface ResidentIdData {
	tenantIdentifier: string;
	residenceIdentifier: string;
	unitIdentifier: string;
	residentId: number;
}

export function getResidentIdData(residentData: Resident): ResidentIdData {
	return {
		tenantIdentifier: residentData?.tenantIdentifier,
		residenceIdentifier: residentData?.residenceIdentifier,
		unitIdentifier: residentData?.unitIdentifier,
		residentId: residentData?.residentId,
	};
}
export function getResidentIdDataFromPath(props): ResidentIdData {
	const tenantId: string = props.match.params.tenantId;
	const residenceId: string = props.match.params.residenceId;
	const unitId: string = props.match.params.unitId;
	const residentId: number = Number(props.match.params.residentId);

	const residentIdData: ResidentIdData = {
		tenantIdentifier: tenantId,
		residenceIdentifier: residenceId,
		unitIdentifier: unitId,
		residentId: residentId,
	};

	return residentIdData;
}

export function isLandscape(): boolean {
	return window.matchMedia('(orientation: landscape)').matches;
}

export function parseOutsideInput(
	outsideInput: OutsideInput
): [number, string, string, string, string] {
	const allegedResidentId: number = Number(
		outsideInput?.inputJson?.subject?.split('[', 2).pop().split(',', 1)
	);
	const allegedUnitId: string = outsideInput?.inputJson?.subject
		?.split(',', 2)
		.pop()
		.split(',', 1)
		.pop()
		.trim();
	const allegedResidenceId: string = outsideInput?.inputJson?.subject
		?.split(',', 3)
		.pop()
		.split(']', 1)
		.pop()
		.trim();
	const allegedSender: string = outsideInput?.inputJson?.body
		?.split('[', 2)
		.pop()
		.split(']', 1)
		.pop()
		.trim();
	const allegedContent: string = outsideInput?.inputJson?.body
		?.split('[', 3)
		.pop()
		.split(']', 1)
		.pop()
		.trim();

	return [
		allegedResidentId,
		allegedUnitId,
		allegedResidenceId,
		allegedSender,
		allegedContent,
	];
}

export const noteCategoryItems = [
	{
		value: NoteCategory.Highlight,
		name: 'Highlight',
		icon: (
			<Tooltip title={'Highlight'}>
				<AutoAwesome color='primary' />
			</Tooltip>
		),
	},
	{
		value: NoteCategory.Fateful,
		name: 'Schicksalsschlag',
		icon: (
			<Tooltip title={'Schicksalsschlag'}>
				<HeartBrokenRoundedIcon color='primary' />
			</Tooltip>
		),
	},
	{
		value: NoteCategory.Change,
		name: 'Umbruch',
		icon: (
			<Tooltip title={'Umbruch'}>
				<SwitchRight color='primary' />
			</Tooltip>
		),
	},

	{
		value: NoteCategory.Encounter,
		name: 'Begegnung',
		icon: (
			<Tooltip title={'Begegnung'}>
				<PersonAddAlt1 color='primary' />
			</Tooltip>
		),
	},

	{
		value: NoteCategory.Party,
		name: 'Fest',
		icon: (
			<Tooltip title={'Fest'}>
				<Celebration color='primary' />
			</Tooltip>
		),
	},
];

export const eventCategoryItems = [
	{
		value: ResidenceEventCategory.Party,
		name: 'Feier',
		icon: <Celebration color='primary' />,
	},
	{
		value: ResidenceEventCategory.Memorial,
		name: 'Gedenken',
		icon: <VolunteerActivismOutlined color='primary' />,
	},
	{
		value: ResidenceEventCategory.Excursion,
		name: 'Ausflug',
		icon: <TravelExplore color='primary' />,
	},
	{
		value: ResidenceEventCategory.Visit,
		name: 'Besucher im Heim',
		icon: <Hail color='primary' />,
	},
	{
		value: ResidenceEventCategory.Culture,
		name: 'Kultur',
		icon: <TheaterComedy color='primary' />,
	},
	{
		value: ResidenceEventCategory.Gifts,
		name: 'Geschenke',
		icon: <CardGiftcard color='primary' />,
	},
	{
		value: ResidenceEventCategory.Retrospect,
		name: 'Rückblick',
		icon: <History color='primary' />,
	},
	{
		value: ResidenceEventCategory.Feast,
		name: 'Festtag',
		icon: <Attractions color='primary' />,
	},
	{
		value: ResidenceEventCategory.Other,
		name: 'Sonstiges',
	},
];

export const groupCategoryItems = [
	{
		value: ResidenceGroupCategory.Reading,
		name: 'Lesen und Vorlesen',
		icon: <AutoStories color='primary' />,
	},
	{
		value: ResidenceGroupCategory.Sports,
		name: 'Bewegung',
		icon: <Sports color='primary' />,
	},
	{
		value: ResidenceGroupCategory.Cooking,
		name: 'Essen und Kochen',
		icon: <OutdoorGrill color='primary' />,
	},
	{
		value: ResidenceGroupCategory.Music,
		name: 'Musik',
		icon: <MusicNote color='primary' />,
	},
	{
		value: ResidenceGroupCategory.Games,
		name: 'Spiel',
		icon: <Extension color='primary' />,
	},
	{
		value: ResidenceGroupCategory.Outside,
		name: 'Draußen',
		icon: <Forest color='primary' />,
	},
	{
		value: ResidenceGroupCategory.Stories,
		name: 'Erzählen',
		icon: <ChatBubbleOutline color='primary' />,
	},
	{
		value: ResidenceGroupCategory.ManualWork,
		name: 'Handarbeit',
		icon: <Handyman color='primary' />,
	},
	{
		value: ResidenceGroupCategory.ManualWork,
		name: 'Unterhaltung',
		icon: <Attractions color='primary' />,
	},
	{
		value: ResidenceGroupCategory.Other,
		name: 'Sonstiges',
	},
];

export const visitFrequencyItems = [
	{
		value: VisitFrequency.Daily,
		name: 'täglich',
	},

	{
		value: VisitFrequency.Everyotherday,
		name: 'mehrmals pro Woche',
	},

	{
		value: VisitFrequency.Weekly,
		name: 'wöchentlich',
	},

	{
		value: VisitFrequency.Everyotherweek,
		name: 'mehrmals pro Monat',
	},

	{
		value: VisitFrequency.Monthly,
		name: 'monatlich',
	},

	{
		value: VisitFrequency.Onholidays,
		name: 'an Festtagen',
	},

	{
		value: VisitFrequency.Rarely,
		name: 'selten',
	},

	{
		value: VisitFrequency.Never,
		name: 'nie',
	},
];

export const favoriteFoodTypeItems = [
	{
		value: FavoriteFoodType.Appetizer,
		name: 'Vorspeise',
		icon: (
			<Tooltip title={'Vorspeise'}>
				<TapasIcon color='primary' sx={StyledIcon} />
			</Tooltip>
		),
	},

	{
		value: FavoriteFoodType.MainCourse,
		name: 'Hauptgang',
		icon: (
			<Tooltip title={'Hauptgang'}>
				<LunchDining color='primary' sx={StyledIcon} />
			</Tooltip>
		),
	},

	{
		value: FavoriteFoodType.Dessert,
		name: 'Nachtisch',
		icon: (
			<Tooltip title={'Nachtisch'}>
				<IcecreamIcon color='primary' sx={StyledIcon} />
			</Tooltip>
		),
	},

	{
		value: FavoriteFoodType.Cake,
		name: 'Kuchen',
		icon: (
			<Tooltip title={'Kuchen'}>
				<Cake color='primary' sx={StyledIcon} />
			</Tooltip>
		),
	},

	{
		value: FavoriteFoodType.Drink,
		name: 'Getränk',
		icon: (
			<Tooltip title={'Getränk'}>
				<LocalBar color='primary' sx={StyledIcon} />
			</Tooltip>
		),
	},

	{
		value: FavoriteFoodType.Other,
		name: 'Sonstige',
		icon: (
			<Tooltip title={'Sonstige'}>
				<Dining color='primary' sx={StyledIcon} />
			</Tooltip>
		),
	},
];

export function mediaTypeToIcon(mediaType: string) {
	if (mediaType.includes('image')) {
		return (
			<ImageIcon
				style={{
					verticalAlign: 'middle',
				}}
			/>
		);
	} else if (mediaType.includes('pdf')) {
		return (
			<PictureAsPdfIcon
				style={{
					verticalAlign: 'middle',
				}}
			/>
		);
	} else if (mediaType?.includes('text')) {
		return (
			<TextSnippetIcon
				style={{
					verticalAlign: 'middle',
				}}
			/>
		);
	} else if (mediaType?.includes('video')) {
		return <OndemandVideoIcon color='primary' />;
	} else if (mediaType?.includes('audio')) {
		return <AudiotrackIcon color='primary' />;
	} else {
		return null;
	}
}

export function abbreviateString(text: string, length: number): string {
	return text?.length > length ? text.substring(0, length) + '...' : text;
}

export function trimString(text: string, length: number): string {
	return text?.length > length ? text.substring(0, length) : text;
}

const StyledBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		color: 'lightgrey',
		backgroundColor: 'grey',
	},
});

interface TCCStyledBadgeButtonProps {
	badgeContent: number;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function TCCStyledBadgeButton({
	badgeContent,
	onClick,
}: TCCStyledBadgeButtonProps) {
	return (
		<Tooltip title='Connect hinzufügen'>
			<IconButton onClick={onClick}>
				<StyledBadge badgeContent={badgeContent} color='secondary'>
					<TCCLogo width={40} height={40} />
				</StyledBadge>
			</IconButton>
		</Tooltip>
	);
}

export function viewportHeight(v): number {
	var h = Math.max(
		document.documentElement.clientHeight,
		window.innerHeight || 0
	);
	return (v * h) / 100;
}

export function conditionalDividerText(list: any[]): string {
	if (list?.length > 0) {
		return list.length === 1 ? 'Neuester Eintrag' : 'Neueste Einträge';
	} else {
		return null;
	}
}

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#ff9999' : '#308fe8',
	},
}));

export function GetDistinctAssessmentTargets(
	motivation: AssessedProfile,
	residentIdData: ResidentIdData
): {
	targetName: string;
	targetDesc: string;
	score: number;
}[] {
	const [assessmentResultList, setAssessmentResultList] = useState(
		[] as AssessmentResult[]
	);

	useEffect(() => {
		const loadAssessmentResultData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessmentResultForResidentAssessment(
					residentIdData.tenantIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residenceIdentifier,
					motivation.residentAssessmentId,
					motivation.assessmentProfileId
				);
				setAssessmentResultList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					}
				} else {
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessmentResultData());
	}, [motivation, residentIdData]);

	// get first 3 elements:
	const threeMostDistinctAssessmentTargets = assessmentResultList
		.sort(
			(obj2, obj1) => Math.abs(obj1.score - 0.5) - Math.abs(obj2.score - 0.5)
		)
		.slice(0, 3);

	// assign the right target to the right score
	let displayDistinctAssessmentTargets: {
		targetName: string;
		targetDesc: string;
		score: number;
	}[] = [];
	for (var el of threeMostDistinctAssessmentTargets) {
		if (el.score > 0.5) {
			displayDistinctAssessmentTargets.push({
				targetName: el.assessmentTargetName,
				targetDesc: el.descriptionLower,
				score: el.score * 100,
			});
		} else {
			displayDistinctAssessmentTargets.push({
				targetName: el.assessmentTargetName,
				targetDesc: el.descriptionUpper,
				score: (1 - el.score) * 100,
			});
		}
	}
	return displayDistinctAssessmentTargets;
}

export function makeDisabledTextField(
	label: string,
	name: string,
	value: string
) {
	return (
		<TextField
			label={label}
			name={name}
			id='outlined-size-normal'
			value={value}
			disabled
		/>
	);
}
